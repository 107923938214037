import type { FieldOption } from '@hostinger/hcomponents';
import { defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';

import { useOnboardingSteps, useDomain } from '@/composables';
import { wordpressRepo, domainsRepo } from '@/repositories';
import type {
  WordPressHowToMigrateAnswer,
  Hosting,
  OrderWithLimits,
  UploadedFile,
  MigrationPlatform,
  ArrayElementType,
} from '@/types';
import {
  MIGRATIONS_ONBOARDING_STEPS,
  STORE_PERSISTENT_KEYS,
  MigrationStatus,
  WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS,
  MIGRATION_PLATFORMS,
  Header,
} from '@/types';
import { mapKeyValue, toASCII } from '@/utils/helpers';
import {
  convertDomainToUrl,
  convertStringToUrl,
  stripDomainUrl,
} from '@/utils/helpers/domainsHelper';

const EMPTY_HOSTING_PLAN = {
  plan: {} as Hosting.Plan,
  title: '',
  domain: '',
  username: '',
  hasAccount: false,
  orderId: '',
  productName: '',
  limits: {
    addons: {
      limit: 0,
      count: 0,
    },
  },
  status: '' as Hosting.OrderStatus,
};

const SQL_EXTENSIONS = ['.sql', '.sql.gz'];
const WPRESS_EXTENSION = '.wpress';

export const useMigrationsOnboardingStore = defineStore(
  'migrationsOnboardingStore',
  () => {
    const { domain } = useDomain();

    const isRedirectedFromOnboarding = computed(() => !!domain.value);

    const migrationsOnboardingSteps = computed(() =>
      Object.values(MIGRATIONS_ONBOARDING_STEPS),
    );

    const enteredWebsiteName = ref('');

    const isUsingTemporaryDomain = ref(false);

    const isUploadingInProgress = ref(false);

    const isWordPressAutoDetected = ref(false);

    const isCpanelAutoDetected = ref(false);

    const isCreatingNewAddon = ref(false);

    const websitesToMigrateOptions = ref<FieldOption[]>([]);

    const selectedWpMigrationType = ref<WordPressHowToMigrateAnswer>(
      WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS.WORDPRESS_ADMIN_DETAILS,
    );

    const selectedMigrationPlatform = ref<MigrationPlatform>(
      MIGRATION_PLATFORMS.OTHER,
    );
    const whereToMigrateSelection = ref('');

    const isFromMigrationRequestForm = ref(false);

    const newWebsiteMigrationDetails = reactive<{
      hostingPlan: OrderWithLimits;
      newWebsiteName: string;
      existingWebsiteName: string;
    }>({
      hostingPlan: EMPTY_HOSTING_PLAN,
      newWebsiteName: '',
      existingWebsiteName: '',
    });

    const wordPressCredentials = reactive({
      username: '',
      password: '',
      loginUrl: '',
    });

    const migrationBackupUrl = ref('');

    const cPanelCredentials = reactive({
      username: '',
      password: '',
      loginUrl: '',
    });

    const otherPanelCredentials = reactive({
      username: '',
      password: '',
      loginUrl: '',
      additionalNotes: '',
    });

    const uploadedFiles = ref<UploadedFile[]>([]);

    const isOnlySqlFileUploaded = computed(
      () =>
        uploadedFiles.value.length === 1 &&
        SQL_EXTENSIONS.some((extension) =>
          uploadedFiles.value[0].name.endsWith(extension),
        ),
    );

    const isWpressFileUploaded = computed(
      () =>
        uploadedFiles.value.length &&
        uploadedFiles.value[0].name.endsWith(WPRESS_EXTENSION),
    );

    const getMigrationRequestBody = async () => {
      const { existingWebsiteName, newWebsiteName } =
        newWebsiteMigrationDetails;

      const enteredDomain =
        domain.value || existingWebsiteName || stripDomainUrl(newWebsiteName);

      const staticValues = {
        domain: toASCII(enteredDomain),
        overwrite: 0,
        panel: isCpanelAutoDetected.value
          ? MIGRATION_PLATFORMS.CPANEL
          : selectedMigrationPlatform.value,
        status: MigrationStatus.PENDING,
      };

      const isWordPressMigrationWithBackup =
        selectedMigrationPlatform.value === MIGRATION_PLATFORMS.WORDPRESS &&
        !wordPressCredentials.loginUrl;

      if (isWordPressMigrationWithBackup) {
        return {
          ...staticValues,
          backupLink: convertDomainToUrl(migrationBackupUrl.value),
        };
      }

      const REQUEST_BODY_CONFIG = {
        [MIGRATION_PLATFORMS.WORDPRESS]: {
          ...staticValues,
          adminLink: convertStringToUrl(wordPressCredentials.loginUrl),
          config: {
            wpUsername: wordPressCredentials.username,
            wpPassword: wordPressCredentials.password,
          },
        },
        [MIGRATION_PLATFORMS.CPANEL]: {
          ...staticValues,
          adminLink: convertStringToUrl(cPanelCredentials.loginUrl),
          config: {
            username: cPanelCredentials.username,
            password: cPanelCredentials.password,
          },
        },
        [MIGRATION_PLATFORMS.OTHER]: {
          ...staticValues,
          adminLink: convertStringToUrl(enteredWebsiteName.value),
          panelLink: convertStringToUrl(otherPanelCredentials.loginUrl),
          config: {
            username: otherPanelCredentials.username,
            password: otherPanelCredentials.password,
          },
          ...(otherPanelCredentials.additionalNotes && {
            info: otherPanelCredentials.additionalNotes,
          }),
        },
        default: staticValues,
      };

      return mapKeyValue(
        REQUEST_BODY_CONFIG,
        isCpanelAutoDetected.value
          ? MIGRATION_PLATFORMS.CPANEL
          : selectedMigrationPlatform.value || 'default',
      );
    };

    const setUploadedFiles = (files: UploadedFile[]) => {
      const updatedFiles = new Map(
        uploadedFiles.value.map((file) => [file.name, file]),
      );

      files.forEach((file) => {
        updatedFiles.set(file.name, file);
      });

      uploadedFiles.value = Array.from(updatedFiles.values());
    };

    const clearUploadedFiles = () => {
      uploadedFiles.value = [];
    };

    const fetchIsWordpressWebsite = async (url: string) => {
      const response = await wordpressRepo.getIsWordpressWebsite(
        convertStringToUrl(url),
      );
      const [{ data: isWordpressWebsite }] = response;

      isWordPressAutoDetected.value = isWordpressWebsite;

      return response;
    };

    const addNewWebsiteAddonForMigration = async () => {
      const { username, orderId } = newWebsiteMigrationDetails.hostingPlan;
      const requestHeaders = {
        [Header.USERNAME]: username,
        [Header.ORDER_ID]: orderId,
      };
      isCreatingNewAddon.value = true;
      const [data, err] = await domainsRepo.addAddon(
        { domain: stripDomainUrl(newWebsiteMigrationDetails.newWebsiteName) },
        requestHeaders,
      );

      if (data && !err) {
        whereToMigrateSelection.value =
          newWebsiteMigrationDetails.newWebsiteName;
      }
      isCreatingNewAddon.value = false;

      return [data, err];
    };

    const isSqlUploaded = computed(() =>
      uploadedFiles.value.some((file) =>
        SQL_EXTENSIONS.some((extension) => file.name.endsWith(extension)),
      ),
    );

    const resetEnteredCredentials = () => {
      wordPressCredentials.username = '';
      wordPressCredentials.password = '';
      wordPressCredentials.loginUrl = '';
      cPanelCredentials.username = '';
      cPanelCredentials.password = '';
      cPanelCredentials.loginUrl = '';
      otherPanelCredentials.username = '';
      otherPanelCredentials.password = '';
      otherPanelCredentials.loginUrl = '';
      otherPanelCredentials.additionalNotes = '';
    };

    const $reset = () => {
      currentStep.value = MIGRATIONS_ONBOARDING_STEPS.WEBSITE_TO_MIGRATE_NAME;
      completedSteps.value = [];
      migrationBackupUrl.value = '';
      whereToMigrateSelection.value = '';
      isUsingTemporaryDomain.value = false;
      enteredWebsiteName.value = '';
      transition.value = 'slide-left';
      newWebsiteMigrationDetails.hostingPlan = EMPTY_HOSTING_PLAN;
      newWebsiteMigrationDetails.newWebsiteName = '';
      newWebsiteMigrationDetails.existingWebsiteName = '';
      selectedWpMigrationType.value =
        WORDPRESS_MIGRATION_HOW_TO_MIGRATE_ANSWERS.WORDPRESS_ADMIN_DETAILS;
      selectedMigrationPlatform.value = MIGRATION_PLATFORMS.OTHER;
      websitesToMigrateOptions.value = [];
      isWordPressAutoDetected.value = false;
      isCpanelAutoDetected.value = false;
      uploadedFiles.value = [];
      isFromMigrationRequestForm.value = false;
      resetEnteredCredentials();
    };

    const {
      currentStep,
      completedSteps,
      previousStep,
      hasPreviousSteps,
      goToStep,
      goToPrevious,
      completionPercentage,
      removeCompletedStep,
      setStep,
      transition,
      setTransition,
      addCompletedStep,
      removePreviousStep,
    } = useOnboardingSteps<
      ArrayElementType<typeof migrationsOnboardingSteps.value>
    >(migrationsOnboardingSteps);

    return {
      removeCompletedStep,
      transition,
      completionPercentage,
      currentStep,
      goToStep,
      goToPrevious,
      completedSteps,
      selectedWpMigrationType,
      getMigrationRequestBody,
      removePreviousStep,
      resetEnteredCredentials,
      addCompletedStep,
      whereToMigrateSelection,
      wordPressCredentials,
      hasPreviousSteps,
      previousStep,
      newWebsiteMigrationDetails,
      setTransition,
      selectedMigrationPlatform,
      cPanelCredentials,
      otherPanelCredentials,
      setStep,
      $reset,
      migrationBackupUrl,
      enteredWebsiteName,
      websitesToMigrateOptions,
      fetchIsWordpressWebsite,
      isWordPressAutoDetected,
      isCpanelAutoDetected,
      isRedirectedFromOnboarding,
      uploadedFiles,
      setUploadedFiles,
      isFromMigrationRequestForm,
      clearUploadedFiles,
      isUploadingInProgress,
      addNewWebsiteAddonForMigration,
      isCreatingNewAddon,
      isSqlUploaded,
      isOnlySqlFileUploaded,
      isWpressFileUploaded,
      isUsingTemporaryDomain,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.MIGRATIONS_ONBOARDING_STORE } },
);
